

























import moment from 'moment'
import VueSlider from 'vue-slider-component'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Presence } from '../StatusRenderer.vue'
import ConditionalLink from './ConditionalLink.vue'
import 'vue-slider-component/theme/default.css'
import TimeBar from '@/components/TimeBar.vue'
import { PresenceStruct } from 'remote-presence-utils/js/types'

@Component({
  components: {
    CLink: ConditionalLink,
    VueSlider,
    TimeBar
  }
})
export default class PresentiChromePresence extends Vue {
  @Prop()
  presence: PresenceStruct;

  get image () {
    if (typeof this.presence.image === 'string') {
      return {
        src: this.presence.image
      }
    }

    return this.presence.image
  }

  get largeText () {
    if (typeof this.presence.largeText === 'string') {
      return {
        text: this.presence.largeText
      }
    }

    return this.presence.largeText
  }

  get smallTexts () {
    if (!this.presence.smallTexts || !Array.isArray(this.presence.smallTexts)) return []

    return this.presence.smallTexts.map(text => {
      if (typeof text === 'string') {
        return {
          text
        }
      }

      return text
    })
  }

  get paused () {
    return this.presence.isPaused
  }

  get gradient () {
    if (typeof this.presence.gradient === 'boolean') {
      return {
        enabled: this.presence.gradient
      }
    };

    return this.presence.gradient
  }

  get title () {
    return this.presence.title
  }

  get assetOnly () {
    return this.image?.src && !(this.largeText?.text) && this.smallTexts.filter(t => !!t && !!t.text).length === 0
  }

  get start () {
    return this.presence.timestamps?.start
  }

  get end () {
    return this.presence.timestamps?.stop
  }
}
