









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConditionalLink extends Vue {
  @Prop()
  link: string;
}
