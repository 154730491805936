














import { Component, Vue } from 'vue-property-decorator'
import SocialLink from '@/components/SocialLink.vue'

@Component({
  components: {
    SocialLink
  }
})
export default class SocialThings extends Vue {
  async copy () {
    await this.$copyText('eric#1111')
    this.$toasted.show('I copied my Discord to the clipboard 💕', { className: 'toast' }).goAway(2000)
  }
}
