
















import { Component, Vue } from 'vue-property-decorator'
import gradstop from 'gradstop'
import SocialThings from '@/components/SocialThings.vue'

enum BackgroundState {
  INIT,
  PRELOAD,
  ROLLING
}

@Component({
  components: {
    SocialThings
  }
})
export default class App extends Vue {
  mounted () {
    this.$el.classList.remove('from-ssr')
  }

  get showMeta () {
    return !this.$store.state.widgetsOnly
  }

  get transition () {
    return `${this.$store.state.transition}ms`
  }

  get background () {
    return this.$store.state.backgroundColor
  }
}
