








import { Component, Vue } from 'vue-property-decorator'
import { PresenceStream } from 'remote-presence-connector'
import { PresenceStruct } from 'remote-presence-utils'
import PresentiPresence from '@/components/PresentiPresence.vue'

const PRESENCE_URL = process.env.VUE_APP_WS || 'ws://localhost:8138/presence/'

export interface Presence {
  name: string;
  type: string;
  url: string | null;
  details: string | null;
  state: string | null;
  applicationID: string | null;
  timestamps?: {
    start: string | null;
    end: string | null;
  };
  party: string | null;
  assets?: {
    largeText?: string;
    smallText?: string;
    largeImage?: string;
    smallImage?: string;
  };
  flags: number;
  emoji: string | null;
  createdTimestamp: number;
  data?: any;
}

export interface PresenceResponse {
  userID: string;
  guild: string;
  status: string;
  activities: Presence[];
  spotifyAssets: {
    [tag: string]: {
      url: string;
      palette: string[];
    };
  };
}

export type SpotifyAssets = PresenceResponse['spotifyAssets'];

interface PresenceState {
  gradient?: {
    color?: string;
    transition?: string;
  };
}

@Component({
  components: {
    PresentiPresence
  }
})
export default class DiscordStatus extends Vue {
  presences: PresenceStruct[] = [];
  presenceState: PresenceState = {};
  stream: PresenceStream = null!;
  effective: number = Date.now();

  created () {
    this.respawnSocket()
  }

  respawnSocket () {
    this.stream = new PresenceStream('eric', { url: PRESENCE_URL })
    this.stream.on('presence', (activities) => { this.presences = activities })
    this.stream.on('state', (state) => { this.presenceState = state })
    this.stream.connect()
  }

  mounted () {
    this.$watch('presenceState', (state: PresenceState) => {
      const { color, transition } = state.gradient || {}
      this.$store.commit('setBackground', color)
      this.$store.commit('setTransition', transition)
    }, { deep: true })
  }
}
