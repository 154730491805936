






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SocialLink extends Vue {
  @Prop()
  link: string;

  @Prop()
  icon: string;
}
